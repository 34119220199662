<template>
  <div class="site-legal-links animate-fade-up animate-once">
    <div class="text-center text-xs gap-1">
      <span v-for="(link, i) in links" :key="link.href">
        <NuxtLink :to="link.href">
          {{ link.title }}
        </NuxtLink>
        <span v-if="i < links.length - 1" class="mx-1.5">•</span>
      </span>
    </div>

    <div class="flex flex-row items-center justify-center gap-2">
      <a href="https://discord.gg/mY6QanRtGU" target="_blank">
        <font-awesome-icon
          icon="fa-brands fa-discord"
          class="text-gray-950 dark:text-gray-600"
          title="Discord"
        />
      </a>

      <a href="https://x.com/realfavoritely" target="_blank">
        <font-awesome-icon
          icon="fa-brands fa-x-twitter"
          class="text-gray-950 dark:text-gray-600"
        />
      </a>
    </div>

    <p class="text-xs text-cool-400 dark:text-cool-700">
      Favoritely LLC, 30 Gould St Ste R
      <br />
      Sheridan, WY 82801
    </p>
  </div>
</template>

<script setup>
  const links = [
    {
      title: "Privacy Policy",
      href: "/privacy",
    },
    // {
    //   title: "Cookie Notice",
    //   href: "/cookie-notice",
    // },
    // {
    //   title: "Anti-Spam Policy",
    //   href: "/anti-spam-policy",
    // },
    {
      title: "Terms of Service",
      href: "/general-terms-of-service",
    },
    // {
    //   title: "Terms for Creators",
    //   href: "/terms-for-creators",
    // },
    // {
    //   title: "Terms for Users",
    //   href: "/terms-for-users",
    // },
    // {
    //   title: "Community Standards",
    //   href: "/community-standards",
    // },
    // {
    //   title: "Billing Support",
    //   href: "/billing-support",
    // },
    {
      title: "18 U.S.C. § 2257",
      href: "/2257",
    },
    {
      title: "DMCA / Take Down Requests",
      href: "/dmca",
    },
    {
      title: "Support",
      href: "https://support.favoritely.com",
    },
    // {
    //   title: "CA Privacy Notice",
    //   href: "/california-privacy-notice",
    // },
  ]
</script>

<style lang="scss" scoped>
  .site-legal-links {
    @apply w-full flex flex-col gap-4 mt-4 pb-6 px-4 text-center;
  }
</style>
